import { useEffect } from "react";

const FacebookPixel = ({ fbIdsStr }: { fbIdsStr: string }) => {
  const fbIds = fbIdsStr.split(",");
  const fbInitStr = fbIds.map((f) => `fbq('init', '${f}');`).join(" ");
  useEffect(() => {
    if (!fbIdsStr) return;
    const script = document.createElement("script");
    const noscript = document.createElement("noscript");

    script.setAttribute("id", "facebookPixel");
    script.text = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); ${fbInitStr} fbq('track', 'PageView');`;

    fbIds.forEach((f) => {
      const img = document.createElement("img");
      img.setAttribute("height", "1");
      img.setAttribute("width", "1");
      img.style.display = "none";
      img.src = `https://www.facebook.com/tr?id=${f}&ev=PageView&noscript=1`;
      noscript.appendChild(img);
    });

    document.head.appendChild(script);
    document.head.appendChild(noscript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(noscript);
    };
  }, []);

  return <></>;
};

export default FacebookPixel;
