import React, { useState } from "react";
import Banner from "./Banner";
import Games from "./Games";
import SignUpForm from "./SignUpForm";
import styles from "./style.module.scss";
import GameModal from "../GameModal";

type SignUpViewProps = {
  setToSuccessView: () => void;
};

const SignUpView = ({ setToSuccessView }: SignUpViewProps) => {
  const [gameUrl, setGameUrl] = useState("");

  return (
    <div className={styles.main}>
      <Banner />
      <Games setGameUrl={setGameUrl} />
      <SignUpForm setToSuccessView={setToSuccessView}/>
      {gameUrl && <GameModal setGameUrl={setGameUrl} gameUrl={gameUrl} />}
    </div>
  );
};

export default SignUpView;
