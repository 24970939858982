// import React from "react";
import styles from "./styles.module.scss";
// import logo from "../../asset/images/logo_light.svg";
// import youtube from "../../asset/images/youtube.svg";
// import ig from "../../asset/images/ig.svg";
// import x from "../../asset/images/x.svg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      
      <div className={styles.security}>
        <hr />
      </div>
      <ul className={styles.link_wrapper}>
        <li>
          <a href="https://www.katsuwin.io/page/terms">利用規約</a>
        </li>
        <div className={styles.divider}></div>
        <li>
          <a href="https://www.katsuwin.io/page/privacy">
            プライバシーポリシー
          </a>
        </li>
        <div className={styles.divider}></div>
        <li>
          <a href="https://www.katsuwin.io/page/cookie-policy">
            クッキー(cookie)ポリシー
          </a>
        </li>
        <div className={styles.divider}></div>
        <li>
          <a href="https://www.katsuwin.io/page/rule">
            キャンペーン一般利用規約
          </a>
        </li>
      </ul>
    
    </footer>
  );
};

export default Footer;
