import { combineReducers } from "@reduxjs/toolkit";
import persistStoresReducer from "./features/persistStoresSlice";
import modalReducer from "./features/modalSlice";
import signUpReducer from "./features/signUpSlice";
const rootReducer = combineReducers({
  persistStores: persistStoresReducer,
  modal: modalReducer,
  signUp: signUpReducer,
});

export default rootReducer;
