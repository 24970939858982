import MatomoAnalytics from "./Matomo";
import FacebookPixel from "./facebookPixel";
import GoogleAnalytics from "./googleAnalytics";
import DableScript from "./dableScript";

type AnalyticsProps = {
  matomoId: string;
  fbIdsStr: string;
  gaId: string;
};

const Analytics = ({ matomoId, fbIdsStr, gaId }: AnalyticsProps) => {
  return (
    <>
      <DableScript />
      <MatomoAnalytics matomoId={matomoId} />
      <FacebookPixel fbIdsStr={fbIdsStr} />
      <GoogleAnalytics gaId={gaId} />
    </>
  );
};

export default Analytics;
