import { Helmet } from "react-helmet";

type GoogleAnalyticsProps = {
  gaId: string;
};
const GoogleAnalytics = ({ gaId }: GoogleAnalyticsProps) => {
  if (!gaId) return <></>;
  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        ></script>
        <script id="google-analytics">
          {`
        window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', "${gaId}");
        `}
        </script>
      </Helmet>
    </>
  );
};

export default GoogleAnalytics;
