import React from "react";
import { SignUpProvider } from "@katsuwin/share-components";
import { useDetectDevices } from "@katsuwin/share-hooks";

type SignUpFormProps = {
  setToSuccessView: () => void;
};

const SignUpForm = ({ setToSuccessView }: SignUpFormProps) => {
  const { isMobile } = useDetectDevices();

  return (
    <SignUpProvider
      gaId={process.env.REACT_APP_GA_ID || ""}
      isPc={!isMobile}
      setToSuccessView={setToSuccessView}
      promotionCode={process.env.REACT_APP_PROMOTION_CODE || ""}
    />
  );
};

export default SignUpForm;
