import React from "react";

const MatomoAnalytics = ({ matomoId }: { matomoId: string }) => {
  React.useEffect(() => {
    if (!matomoId) return;
    //@ts-ignore
    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      var u = "https://matomo.wegoing.io/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", matomoId]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      //@ts-ignore
      s.parentNode.insertBefore(g, s);
    })();
  }, []);
  return <></>;
};

export default MatomoAnalytics;
