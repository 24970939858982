import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../constant/url";
type Data =
  | {
      body?: BodyInit | null;
      slug?: string;
      dynamicApi?: string;
    }
  | null
  | undefined;

type ThunkServe = {
  thunkId: string;
  method: string;
  // data?: {
  //   body?: BodyInit | null;
  // } | null;
  apiUrl: string;
};
export const thunkServe = ({ thunkId, method = "GET", apiUrl }: ThunkServe) =>
  createAsyncThunk(
    thunkId,
    // if you type your function argument here
    // Data 是從 component 使用時傳遞進來的資料, 可從 type Data 去擴充
    async (data: Data, { rejectWithValue }) => {
      const fetchConfig = {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
        method,
        body: data?.body ?? null,
      };

      const handleDynamicApi = data?.dynamicApi ? data?.dynamicApi : "";
      try {
        // 如果有 data?.dynamicApi, apiUrl 即為空自串
        const combineUrl = BASE_URL + apiUrl + handleDynamicApi;
        const response = await fetch(combineUrl, fetchConfig);
        return await response.json();
      } catch (err: any) {
        console.log("thunk api error", err);
        return rejectWithValue(err.response.data);
      }
    }
  );
