import { Helmet } from "react-helmet";

const DableScript = () => {
  return (
    <Helmet>
      <script id="dableScript">
        {`(function(d,a,b,l,e,_) {
    d[b]=d[b]||function(){(d[b].q=d[b].q||[]).push(arguments)};
    e=a.createElement(l); 
    e.async=1;e.charset='utf-8';
    e.src='//static.dable.io/dist/dablena.min.js';
     _=a.getElementsByTagName(l)[0];
     _.parentNode.insertBefore(e,_); 
     }
     )(window,document,'dablena','script');
      dablena('init', '003-009-533'); 
      dablena('track', 'PageView');`}
      </script>
    </Helmet>
  );
};

export default DableScript;
