import iconCheckMark from "../../asset/images/icon_check_mark.png";
import styles from "./styles.module.scss";
import eventDeposit1 from "../../asset/images/event_deposit_round_1.webp";
import eventDeposit2 from "../../asset/images/event_deposit_round_2.webp";
import eventDeposit3 from "../../asset/images/event_deposit_round_3.webp";
import eventReferralRebate from "../../asset/images/event_referral_rebate.webp";
import eventPrestigePlan from "../../asset/images/event_VIP_prestige_plan.webp";

const SignUpSuccessView = () => {
  const eventList: { imgPath: string; altText: string; href: string }[] = [
    {
      imgPath: eventDeposit1,
      altText: "盛大にオープン！1回目の入金100%",
      href: "https://www.katsuwin.io/event",
    },
    {
      imgPath: eventDeposit2,
      altText: "盛大にオープン！2回目の入金50%",
      href: "https://www.katsuwin.io/event",
    },
    {
      imgPath: eventDeposit3,
      altText: "盛大にオープン！3回目の入金100%",
      href: "https://www.katsuwin.io/event",
    },
    {
      imgPath: eventReferralRebate,
      altText: "紹介ボーナス",
      href: "https://katsuwin.info/shareproject",
    },
    {
      imgPath: eventPrestigePlan,
      altText: "KatsuWINでは素敵なVIPプロジェクト をご用意しております。",
      href: "https://www.katsuwin.io/event",
    },
  ];
  return (
    <article className={styles.container}>
      <section>
        <img src={iconCheckMark} alt="check mark" />
        <h1>おめでとうございます！</h1>
        <p>
          おめでとうございます <br />
          登錄に成功しました
        </p>
        <a href="https://www.katsuwin.io/" target="_blank" rel="noreferrer">
          公式ウェブサイト
        </a>
        <a
          href="https://twitter.com/katsuwinonline"
          target="_blank"
          rel="noreferrer"
        >
          公式Ｘ (旧Twitter)
        </a>
      </section>
      <section>
        <p>進行中の素晴らしいイベント</p>
        <hr />
        <ul>
          {eventList.map((event) => (
            <li key={event.altText}>
              <a href={event.href} target="_blank" rel="noreferrer">
                <img src={event.imgPath} alt={event.altText} />
              </a>
            </li>
          ))}
        </ul>
      </section>
    </article>
  );
};

export default SignUpSuccessView;
