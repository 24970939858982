import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { matoTrace } from "@katsuwin/share-utils";
import {
  decrementEmailTimer,
  postEmailVerifyCodeThunk,
  resetEmailTimer,
} from "../../../../../store/features/signUpSlice";

const useVerifyEmail = () => {
  const dispatch = useAppDispatch();
  const { emailVerifyTimer, initialTimer, emailVerifyRes } = useAppSelector(
    (state) => state.signUp
  );
  const [email, setEmail] = useState("");
  const [isCorrectEmail, setIsCorrectEmail] = useState(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const isPcRef = useRef<boolean>(true);
  const sending = useRef<boolean>(false);

  // email 格式, 如果正確才可以發驗證信
  useEffect(() => {
    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      setIsCorrectEmail(false);
      return;
    }
    setIsCorrectEmail(true);
  }, [email]);

  const handleVerifyEmail = (isPc: boolean) => {
    // email 不正確或 timer 啟動時不能點選按鈕
    if (!isCorrectEmail || emailVerifyTimer < initialTimer) return;
    setIsStartTimer(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", "katsuwin");
    formData.append("language", "jp");
    matoTrace(isPc ? "pc" : "mobile", "send_email", email);
    isPcRef.current = isPc;
    sending.current = true;
    dispatch(
      postEmailVerifyCodeThunk({
        body: formData,
      })
    );
  };

  useEffect(() => {
    if (!!emailVerifyRes?.status && sending.current) {
      sending.current = false;
      matoTrace(
        isPcRef.current ? "pc" : "mobile",
        `send_email_result_${emailVerifyRes?.status}_${emailVerifyRes?.msg}`,
        email
      );
    }
  }, [emailVerifyRes, email]);

  useEffect(() => {
    if (!isStartTimer) {
      return;
    }

    const intervalId = setInterval(() => {
      dispatch(decrementEmailTimer());
    }, 1000);
    if (emailVerifyTimer === 0) {
      setIsStartTimer(false);
      dispatch(resetEmailTimer());
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isStartTimer, emailVerifyTimer, dispatch]);

  useEffect(() => {
    if (isStartTimer) return setIsDisable(true);
    if (isCorrectEmail) return setIsDisable(false);
  }, [isStartTimer, isCorrectEmail]);

  return {
    email,
    isCorrectEmail,
    isStartTimer,
    isDisable,
    handleVerifyEmail,
    handleEmailChange,
  };
};
export default useVerifyEmail;
