import { ReactNode } from "react";
import style from "./style.module.scss";

type PageLayoutProps = {
  children?: ReactNode;
  backgroundImg?: string;
  mainBackgroundColor?: string;
};

const PageLayout = ({
  children = <></>,
  backgroundImg,
  mainBackgroundColor,
}: PageLayoutProps) => {
  return (
    <div
      className={style.container}
      style={backgroundImg ? { background: `url(${backgroundImg})` } : {}}
    >
      <div className={style.scroll_wrapper}>
        <main
          style={
            mainBackgroundColor ? { background: `${mainBackgroundColor}` } : {}
          }
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default PageLayout;
