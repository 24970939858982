import { useEffect } from "react";
import { checkCodeAction, createCodeAction } from "./action";
import { useLocation } from "react-router-dom";

const HandleSetPromotionCode = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const promotionCode = searchParams.get("code") || "";
  const handleAction = () => {
    const isCode = checkCodeAction();
    if (isCode) return;
    createCodeAction(promotionCode ?? "");
  };

  useEffect(() => {
    if (promotionCode) handleAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionCode]);

  return null;
};

export default HandleSetPromotionCode;
