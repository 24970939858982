import { createSlice } from "@reduxjs/toolkit";
import { thunkServe } from "../../lib/thunkRequest";
import { matoTrace } from "@katsuwin/share-utils";

export const postEmailVerifyCodeThunk = thunkServe({
  thunkId: "modal/emailVerifyCode",
  method: "POST",
  apiUrl: "/user/get-email-code",
});
export const postSignUpFormSubmitThunk = thunkServe({
  thunkId: "modal/signUpFormSubmit",
  method: "POST",
  apiUrl: "/user/register",
});

type EmailVerifyRes = {
  msg: string;
  data: null | string;
  status: number | null;
} | null;
type FormSubmitRes = {
  msg: string;
  data: null | object;
  status: number | null;
} | null;

export interface modalState {
  initialTimer: number;
  isEmailVerifyLoading: boolean;
  emailVerifyError: string;
  emailVerifyRes: EmailVerifyRes;
  emailVerifyTimer: number;
  isFormSubmitLoading: boolean;
  formSubmitError: string;
  forSubmitRes: FormSubmitRes;
}

const initialState: modalState = {
  initialTimer: 300,
  isEmailVerifyLoading: true,
  emailVerifyRes: null,
  emailVerifyError: "",
  emailVerifyTimer: 300,
  isFormSubmitLoading: true,
  formSubmitError: "",
  forSubmitRes: null,
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    resetSubmitInfo: (state) => {
      state.formSubmitError = "";
      state.forSubmitRes = null;
    },
    // setEmailVerifyRes: (state, action: PayloadAction<EmailVerifyRes>) => {
    //   state.emailVerifyRes = action.payload;
    // },
    decrementEmailTimer: (state) => {
      state.emailVerifyTimer -= 1;
    },
    resetEmailTimer: (state) => {
      state.emailVerifyTimer = state.initialTimer;
    },
  },
  extraReducers: (builder) => {
    // mail Verify api
    builder.addCase(postEmailVerifyCodeThunk.pending, (state) => {
      state.isEmailVerifyLoading = true;
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
    });
    builder.addCase(postEmailVerifyCodeThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.isEmailVerifyLoading = false;
      state.emailVerifyRes = payload;
    });
    builder.addCase(postEmailVerifyCodeThunk.rejected, (state, action) => {
      state.isEmailVerifyLoading = false;
      state.emailVerifyError = JSON.stringify(action.error);
      matoTrace(
        "send-email-rejected",
        "send-email-rejected",
        JSON.stringify(action.error)
      );
    });

    // formSubmit api
    builder.addCase(postSignUpFormSubmitThunk.pending, (state) => {
      state.isFormSubmitLoading = true;
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
    });
    builder.addCase(postSignUpFormSubmitThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.isFormSubmitLoading = false;
      state.forSubmitRes = payload;
      if (payload?.status && payload.status >= 400) {
        state.formSubmitError = payload.msg;
      }
    });
    builder.addCase(postSignUpFormSubmitThunk.rejected, (state, action) => {
      state.isFormSubmitLoading = false;
      state.formSubmitError = JSON.stringify(action.error);
    });
  },
});

// Action creators are generated for each case reducer function
export const { decrementEmailTimer, resetEmailTimer, resetSubmitInfo } =
  signUpSlice.actions;

export default signUpSlice.reducer;
