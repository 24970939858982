// gtag_report_conversion 目前只用來放在註冊畫面, 當資料填完, 按下註冊扭時才觸發
export function gtag_report_conversion({ gaId = "", adEventTriggerId = "" }) {
  if (!window?.gtag || !adEventTriggerId || !gaId) return;
  var callback = function () {
    // func 被 trigger 時要做的事情
    // if (typeof url !== "undefined") {
    //   window.location = url;
    // }
  };
  window.gtag("event", "conversion", {
    send_to: `${gaId}/${adEventTriggerId}`,
    event_callback: callback,
  });
  return false;
}
