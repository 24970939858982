import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

import useVerifyEmail from "../CustomModal/v1/ModalContent/SignUpModal/useVerifyEmail";
import useVerifyPhone from "../CustomModal/v1/ModalContent/SignUpModal/useVerifyPhone";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  postSignUpFormSubmitThunk,
  resetSubmitInfo,
} from "../../store/features/signUpSlice";
import { matoTrace, gtag_report_conversion } from "@katsuwin/share-utils";
import japanIconPath from "./images/japan.svg";
import { getCodeAction } from "../HandleSetPromotionCode/action";
import { CiCalendarDate } from "react-icons/ci";

type FormTarget = {
  username: { value: string };
  newPassword: { value: string };
  confirmPassword: { value: string };
  email: { value: string };
  emailValidateCode: { value: string };
  phone: { value: string };
  romanizationFst: { value: string };
  romanizationLast: { value: string };
  nameFst: { value: string };
  nameLast: { value: string };
  birthday: { value: string };
  promotionCode: { value: string | undefined };
  // checkRule?: { value: boolean };
};

type SignUpFormProps = {
  isPc: boolean;
  gaId: string;
  adEventTriggerId?: string;
  setToSuccessView?: () => void;
  promotionCode: string;
};

const SignUpForm = ({
  isPc,
  gaId,
  adEventTriggerId,
  setToSuccessView,
  promotionCode,
}: SignUpFormProps) => {
  const dispatch = useAppDispatch();
  const { forSubmitRes, formSubmitError, emailVerifyTimer, emailVerifyRes } =
    useAppSelector((state) => state.signUp);

  const formRef = useRef<HTMLFormElement>(null);
  const isSubmiting = useRef(false);
  const [submitEmail, setSubmitEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const { phone, isCorrectPhone, handlePhoneChange } = useVerifyPhone();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormTarget>({
    username: { value: "" },
    newPassword: { value: "" },
    confirmPassword: { value: "" },
    email: { value: "" },
    emailValidateCode: { value: "" },
    phone: { value: "" },
    romanizationFst: { value: "" },
    romanizationLast: { value: "" },
    nameFst: { value: "" },
    nameLast: { value: "" },
    birthday: { value: "" },
    promotionCode: { value: promotionCode || "" },
    // checkRule: { value: false },
  });

  const {
    email,
    isCorrectEmail,
    isStartTimer,
    isDisable,
    handleVerifyEmail,
    handleEmailChange,
  } = useVerifyEmail();

  const isPasswordValid =
    formData.newPassword.value === formData.confirmPassword.value;
  const isEmailValid =
    !!emailVerifyRes?.status &&
    emailVerifyRes?.status >= 400 &&
    email === verifyEmail;

  const handleInputChange = (e: {
    target: { name: any; value: any; checked?: boolean };
  }) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => {
      if (name === "checkRule") {
        return { ...prevData, [name]: { value: checked } };
      }
      return { ...prevData, [name]: { value: value } };
    });
  };

  const checkFormValidity = () => {
    let isValid = false;
    isValid = Object.entries(formData).every(([key, value]) => {
      if (key === "promotionCode") {
        return true;
      }
      if (key === "checkRule") {
        return value.value;
      }
      if (typeof value.value === "string") {
        return value.value.trim() !== "";
      }
    });
    setIsFormValid(isValid);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    // 👇️ prevent page refresh
    e.preventDefault();

    const formatPhone = () => {
      /*     
          ex: 使用者輸入 08012345678
          call api時需轉化為 +81 80-1234-5678
          ex: 使用者輸入 8012345678
          call api時需轉化為 +81 80-1234-5678
        */
      if (phone.length === 11) {
        const formattedPhoneNumber = `+81 ${phone
          .slice(1)
          .replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")}`;
        return formattedPhoneNumber;
      }
      if (phone.length === 10) {
        const formattedPhoneNumber = `+81 ${phone.replace(
          /(\d{2})(\d{4})(\d{4})/,
          "$1-$2-$3"
        )}`;
        return formattedPhoneNumber;
      }
      return "";
    };
    const formatRomanization = `${formData.romanizationFst.value},${formData.romanizationLast.value}`;
    const formatName = `${formData.nameFst.value},${formData.nameLast.value}`;

    setSubmitEmail(email);
    const emailValidateCode = formData.emailValidateCode.value; // typechecks!

    const submitFormData = new FormData();
    submitFormData.append("username", formData.username.value);
    submitFormData.append("newPassword", formData.newPassword.value);
    submitFormData.append("confirmPassword", formData.confirmPassword.value);
    submitFormData.append("email", formData.email.value);
    submitFormData.append("emailValidateCode", emailValidateCode);
    submitFormData.append("password", formData.newPassword.value);
    submitFormData.append("phone", formatPhone());
    submitFormData.append("romanization", formatRomanization);
    submitFormData.append("name", formatName);
    submitFormData.append("birthday", formData.birthday.value);

    submitFormData.append("code", "katsuwin");
    submitFormData.append("language", "jp");
    submitFormData.append("coupon_code", "katsuwin55");

    if (formData.promotionCode.value) {
      submitFormData.append("promotion_code", formData.promotionCode.value);
    }

    dispatch(
      postSignUpFormSubmitThunk({
        body: submitFormData,
      })
    );

    isSubmiting.current = true;

    matoTrace(isPc ? "pc" : "mobile", "regist_click", email);
    gtag_report_conversion({
      gaId: gaId,
      adEventTriggerId: adEventTriggerId,
    });
  };

  // unmount 時要 reset submit Info
  useEffect(() => {
    return () => {
      dispatch(resetSubmitInfo());
    };
  }, [dispatch]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      promotionCode: { value: getCodeAction() },
    }));
  }, []);

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    if (
      forSubmitRes?.status &&
      forSubmitRes?.status >= 200 &&
      forSubmitRes?.status <= 299
    ) {
      // 註冊成功 redirect
      if (setToSuccessView) {
        setToSuccessView();
      }

      matoTrace(isPc ? "pc" : "mobile", "regist_success", submitEmail);

      const script = document.createElement("script");
      //@ts-ignore
      script.setAttribute("id", "Dable_CompleteRegistration");
      script.type = "text/javascript";
      script.innerHTML = `(function (d, a, b, l, e, _) {
         d[b] =
           d[b] ||
           function () {
             (d[b].q = d[b].q || []).push(arguments);
           };
         e = a.createElement(l);
         e.async = 1;
         e.charset = "utf-8";
         e.src = "//static.dable.io/dist/dablena.min.js";
         _ = a.getElementsByTagName(l)[0];
         _.parentNode.insertBefore(e, _);
       })(window, document, "dablena", "script");
       dablena("init", "003-009-533");
       dablena("track", "CompleteRegistration");`;
      //@ts-ignore
      document.head.appendChild(script);
    } else if (
      isSubmiting.current &&
      !!forSubmitRes?.status &&
      forSubmitRes?.status >= 400
    ) {
      matoTrace(
        isPc ? "pc" : "mobile",
        `regist_fail_${forSubmitRes.status}_${forSubmitRes.msg}`,
        submitEmail
      );
      isSubmiting.current = false;
    }
  }, [dispatch, forSubmitRes, submitEmail, isPc, setToSuccessView]);

  return (
    <div className={styles.wrapper}>
      <form
        ref={formRef}
        className={styles.form_container}
        onSubmit={handleSubmit}
      >
        {/* account info */}
        <section className={styles.form_section}>
          <div className={styles.form_sub_title_block}>
            <p>アカウントを作成</p>
            <hr />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="username">アカウント</label>
            <input
              id="username"
              name="username"
              type="text"
              placeholder="半角英数8-16文字でご入力ください"
              required
              minLength={8}
              maxLength={16}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="newPassword">パスワード</label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder="半角英数8-16文字でご入力ください"
              required
              minLength={6}
              maxLength={20}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="confirmPassword">パスワード確認</label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="パスワードを再入力してください"
              required
              minLength={6}
              maxLength={20}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.input_wrapper}>
            <label htmlFor="email">メールアドレス</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="メールアドレス"
              onChange={(e) => {
                handleEmailChange(e);
                handleInputChange(e);
              }}
              required
            />
          </div>

          <div className={styles.verify_wrapper}>
            <button
              type="button"
              onClick={() => {
                handleVerifyEmail(isPc);
                setVerifyEmail(email);
              }}
              disabled={isDisable}
              style={isCorrectEmail && !isStartTimer ? {} : { opacity: "0.2" }}
            >
              {isStartTimer ? `${emailVerifyTimer} s` : "認証コード"}
            </button>
            <input
              id="emailValidateCode"
              name="emailValidateCode"
              type="emailValidateCode"
              placeholder="認証コードを入力"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.input_wrapper}>
            <label htmlFor="phone">携帯番号</label>
            <div className={styles.phone_input_container}>
              <input
                className={styles.phone_input}
                id="phone"
                name="phone"
                type="text"
                required
                minLength={10}
                maxLength={11}
                onChange={(e) => {
                  handlePhoneChange(e);
                  handleInputChange(e);
                }}
              />
              <img
                src={japanIconPath}
                alt="japan icon"
                className={styles.phone_icon}
                width={52}
                height={27}
              />
            </div>
          </div>
        </section>

        {/* personal info */}
        <section className={styles.form_section}>
          <div className={styles.form_sub_title_block}>
            <p>個人情報</p>
            <hr />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="romanizationFst">姓（ローマ字）</label>
            <input
              id="romanizationFst"
              name="romanizationFst"
              type="text"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="romanization-last">名（ローマ字）</label>
            <input
              id="romanizationLast"
              name="romanizationLast"
              type="text"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="nameFst">姓（漢字）</label>
            <input
              id="nameFst"
              name="nameFst"
              type="text"
              placeholder="身分証明書に記載の通りにご入力ください"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="nameLast">名（漢字）</label>
            <input
              id="nameLast"
              name="nameLast"
              type="text"
              placeholder="身分証明書に記載の通りにご入力ください"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="birthday">生年月日</label>
            <div className={styles.birthday_input_container}>
              <input
                id="birthday"
                name="birthday"
                type="date"
                onChange={handleInputChange}
                required
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                className={styles.birthday}
              ></input>
              <CiCalendarDate className={styles.icon_view} />
            </div>
          </div>
          <div className={styles.input_wrapper}>
            <label htmlFor="promotionCode">
              プロモーション
              <br className={styles.pc_br} />
              コード
            </label>
            <input
              id="promotionCode"
              name="promotionCode"
              type="text"
              placeholder="該当なしの場合は空欄にしてください"
              onChange={handleInputChange}
              value={formData.promotionCode.value}
            />
          </div>
        </section>

        {/* rule checkbox */}
        {/* <section className={styles.check_section}>
          <input
            type="checkbox"
            name="checkRule"
            onChange={handleInputChange}
            required
          />
          <p>利用規約に同意します《利用規約》</p>
        </section> */}

        {/* submit button */}
        <button
          className={styles.submit}
          type="submit"
          disabled={
            !isFormValid && !isCorrectPhone && !isPasswordValid && isEmailValid
          }
        >
          <div
            style={
              isFormValid && isCorrectPhone && isPasswordValid && !isEmailValid
                ? { background: "#978564" }
                : {}
            }
          >
            アカウントを作成
          </div>
        </button>

        {/* error message */}
        {(formSubmitError || !isPasswordValid || isEmailValid) && (
          <section className={styles.warning_section}>
            {formSubmitError && (
              <p className={styles.warning}>{`*${formSubmitError}`}</p>
            )}
            {!isPasswordValid && (
              <p className={styles.warning}>
                {`*パスワードが正しくありません、確認の後、再実行してください`}
              </p>
            )}
            {!isCorrectPhone && phone && (
              <p className={styles.warning}>
                {`*携帯番号の形式が正しくありません。もう一度入力してください。`}
              </p>
            )}
            {isEmailValid && (
              <p className={styles.warning}>{`*${emailVerifyRes.msg}`}</p>
            )}
          </section>
        )}

        {/* already have account? */}
        <section className={styles.login_section}>
          <p>
            アカウントをあ持ちですか?{" "}
            <a href="https://www.katsuwin.io/">ログイン</a>
          </p>
        </section>
      </form>
    </div>
  );
};

export default SignUpForm;
