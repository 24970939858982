import React from "react";

const Banner = () => {
  return (
    <div>
      <p>
        フレンド招待状が来ています。誠にメンバーにご登録いただ
        <br className="max-sm:hidden" />
        くことを心よりお待ちしております。
      </p>
      <img
        src="/banner.webp"
        alt="今なら無料登録で5000円の特典がもらえる"
        className="mt-[48px]"
      />
    </div>
  );
};

export default Banner;
