export const createCodeAction = (newCode: string) => {
  if (!newCode) return;
  document.cookie = `code=${newCode}; max-age=${24 * 60 * 60}`;
};

// Function to check if code exists in cookies
export const checkCodeAction = () => {
  return document.cookie.includes("code");
};

export const getCodeAction = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("code="))
    ?.split("=")[1];
  return (
    cookieValue ||
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("code="))
      ?.split("=")[1]
  );
};
