import PageLayout from "./components/PageLayout";
import styles from "./styles.module.scss";
import { Analytics, HandleSetPromotionCode } from "@katsuwin/share-components";
import backgroundPath from "./asset/images/background.webp";
import "./App.css";
import { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SignUpView from "./components/SignUpView";
import SignUpSuccessView from "./components/SignUpSuccessView";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export type PageState = "SIGN_UP" | "SIGN_UP_SUCCESS";

function App() {
  const [viewState, setViewState] = useState<string>("SIGN_UP");

  const app = (
    <PageLayout backgroundImg={backgroundPath}>
      <article className={styles.article}>
        <Header />
        {viewState === "SIGN_UP" ? (
          <SignUpView
            setToSuccessView={() => setViewState("SIGN_UP_SUCCESS")}
          />
        ) : (
          <SignUpSuccessView />
        )}
      </article>

      <Footer />
    </PageLayout>
  );
  return (
    <>
      <Analytics
        matomoId={process.env.REACT_APP_MATOMO_ID || ""}
        fbIdsStr={process.env.REACT_APP_PIXEL_IDS || ""}
        gaId={process.env.REACT_APP_GA_ID || ""}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={app} />
          <Route
            path="/share"
            element={
              <>
                <HandleSetPromotionCode />
                {app}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
