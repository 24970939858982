import React from "react";
import styles from "./style.module.scss";

const Games = ({ setGameUrl }: { setGameUrl: (state: string) => void }) => {
  const gameList = [
    {
      title: "花魁ドリーム 無料体験",
      img: "/games/one.webp",
      url: "https://cdn.oryxgaming.com/badges/GMT/_OD1/v2.0.11/index.html?token=token&gameCode=GMT_OIRAN_DREAM&languageCode=ENG&playMode=FUN",
    },
    {
      title: "スターライト・プリンセス 無料体験",
      img: "/games/two.webp",
      url: "https://demo.tiger-games.com/demo/link?game_code=vs20starlight&game_provider=Pragmatic Play",
    },
    {
      title: "ゲート・オブ・オリュンポス 無料体験",
      img: "/games/three.webp",
      url: "https://demo.tiger-games.com/demo/link?game_code=vs20olympgate&game_provider=Pragmatic Play",
    },
    {
      title: "ムーン・プリンセス 無料体験",
      img: "/games/four.webp",
      url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=moonprincess&lang=en_GB&practice=1&channel=desktop&demo=2",
    },
  ];
  return (
    <div className={styles.img_group}>
      {gameList.map((game) => (
        <img
          key={game.title}
          src={game.img}
          onClick={() => setGameUrl(game.url)}
          alt={game.title}
        />
      ))}
    </div>
  );
};

export default Games;
