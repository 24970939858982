import modalPcBgPath from "./images/background/modalPcBg.webp";
export const modalContainerStyle: React.CSSProperties = {
  background: `url(${modalPcBgPath}) no-repeat`,
  backgroundSize: "cover",
  width: "724px",
  height: `92vh`,
  minWidth: "724px",
};

export const closeWrapperStyle: React.CSSProperties = {
  padding: "2vh 20px",
};

