import React from "react";
import styles from "./styles.module.scss";
import logoImg from "../../asset/images/logo.png";

const Header = () => {
  return (
    <header className={styles.header}>
      <img src={logoImg} alt="Katsuwin logo" width={60} height={72}/>
    </header>
  );
};

export default Header;
