import React from "react";
import ReduxProvider from "../../store/ReduxProvider";
import SignUpForm from "../../components/SignUpForm";

type SignUpFormProps = {
  isPc: boolean;
  gaId: string;
  adEventTriggerId?: string;
  setToSuccessView: () => void;
  promotionCode: string;
};

const SignUpProvider = (props: SignUpFormProps) => {
  return (
    <ReduxProvider>
      <SignUpForm
        gaId={props.gaId}
        isPc={props.isPc}
        setToSuccessView={props.setToSuccessView}
        promotionCode={props.promotionCode}
      />
    </ReduxProvider>
  );
};

export default SignUpProvider;
