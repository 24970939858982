import { useEffect } from "react";
import styles from "./styles.module.scss";

type GameModalProps = {
  setGameUrl: (state: string) => void;
  gameUrl: string;
};

const GameModal = ({ setGameUrl, gameUrl }: GameModalProps) => {
  const close = () => {
    setGameUrl("");
  };

  useEffect(() => {
    if (gameUrl) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [gameUrl]);

  return (
    <div className={styles.modal_wrapper}>
      <div className={styles.iframe_container}>
        <iframe
          className={styles.iframe}
          scrolling="no"
          title="katsuwinfree"
          src={gameUrl}
        />
      </div>
      <button onClick={close}>体験版を終了する</button>
    </div>
  );
};

export default GameModal;
